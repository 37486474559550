// tweetsData.js

// Import utilities for random selection
function getRandomElement(array) {
    return array[Math.floor(Math.random() * array.length)];
}

// Define and export the getTweets function
export function getTweets() {
    // Example tweets array (real and fake). Replace this with your actual data.
    const tweets = {
        fake: [
            {
                "tweet_type": "fake",
                "id": 1,
                "tweet_content": "I don't know … But I think this Ebola epidemic is a form of population control. Shit is getting crazy bruh.",
                "tweet_year": 2014
            },
            {
                "tweet_type": "fake",
                "id": 2,
                "tweet_content": "Dropping my new song Monday if this gets 100k retweets",
                "tweet_year": 2014
            },
            {
                "tweet_type": "fake",
                "id": 3,
                "tweet_content": "Does anybody think global warming is a good thing? I love lady Gaga. I think she's a really interesting artist.",
                "tweet_year": 2011
            },
            {
                "tweet_type": "fake",
                "id": 4,
                "tweet_content": "I can't sleep gotta get 2 walmart",
                "tweet_year": 2009
            },
            {
                "tweet_type": "fake",
                "id": 5,
                "tweet_content": "I can't believe my grandmothers making me take out the garbage I'm rich fuck this I'm going home I don't need this shit",
                "tweet_year": 2010
            },
            {
                "tweet_type": "fake",
                "id": 6,
                "tweet_content": "On email talking to Obama about this whole troop situation. Wow",
                "tweet_year": 2011
            },
            {
                "tweet_type": "fake",
                "id": 7,
                "tweet_content": "I no longer have a manager. I can't be managed",
                "tweet_year": 2018
            },
            {
                "tweet_type": "fake",
                "id": 8,
                "tweet_content": "I need a room full of mirrors so I can be surrounded by winners.",
                "tweet_year": 2014
            },
            {
                "tweet_type": "fake",
                "id": 9,
                "tweet_content": "Once again I am being attacked for presenting new ideas",
                "tweet_year": 2018
            },
            {
                "tweet_type": "fake",
                "id": 10,
                "tweet_content": "I'm nice at ping pong",
                "tweet_year": 2019
            },
            {
                "tweet_type": "fake",
                "id": 11,
                "tweet_content": "You may be talented but you're not Kanye West",
                "tweet_year": 2012
            },
            {
                "tweet_type": "fake",
                "id": 12,
                "tweet_content": "I have to dress Kim everyday so she doesn't embarrass me",
                "tweet_year": 2012
            },
            {
                "tweet_type": "fake",
                "id": 13,
                "tweet_content": "I hate when im on a flight and I wake up with a water bottle next to me like oh great now I gotta be responsible for this water bottle",
                "tweet_year": 2011
            },
            {
                "tweet_type": "fake",
                "id": 14,
                "tweet_content": "I have discovered my single greatest quality. I care.",
                "tweet_year": 2016
            },
            {
                "tweet_type": "fake",
                "id": 15,
                "tweet_content": "Our brother is lost and confused",
                "tweet_year": 2018
            },
            {
                "tweet_type": "fake",
                "id": 16,
                "tweet_content": "Kobe Bryant was a planned killing just like they tried to get me. I dodged the ambush don't trust nobody this is a big dirty industry.",
                "tweet_year": 2020
            },
            {
                "tweet_type": "fake",
                "id": 17,
                "tweet_content": "Just for the record. I have never used the term “goblin mode”",
                "tweet_year": 2022
            },
            {
                "tweet_type": "fake",
                "id": 18,
                "tweet_content": "Why is rhode island nor a road or an island",
                "tweet_year": 2009
            },
            {
                "tweet_type": "fake",
                "id": 19,
                "tweet_content": "My daughter's only 6 months old and already drawing. I'd hang it outside on the fridge but honestly it's absolute garbage.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "fake",
                "id": 20,
                "tweet_content": "If everybody in the world dropped out of school we would have a much more intelligent society",
                "tweet_year": 2013
            },
            {
                "tweet_type": "fake",
                "id": 21,
                "tweet_content": "Hahahaha how the fuck is cyberbullying even real hahah just walk away from the screen like damn close your eyes hahaha",
                "tweet_year": 2012
            },
            {
                "tweet_type": "fake",
                "id": 22,
                "tweet_content": "The magic of memes hangs like a mist around us",
                "tweet_year": 2017
            },
            {
                "tweet_type": "fake",
                "id": 24,
                "tweet_content": "The great killer is cold. Not heat. Global warming has actually been saving lives.",
                "tweet_year": 2021
            },
            {
                "tweet_type": "fake",
                "id": 25,
                "tweet_content": "I'm a christian. So they may drive me to my knees but that's where I'm the strongest.",
                "tweet_year": 2020
            },
            {
                "tweet_type": "fake",
                "id": 27,
                "tweet_content": "We're not talking about the first amendment. We're talking about free speech.",
                "tweet_year": 2021
            },
            {
                "tweet_type": "fake",
                "id": 28,
                "tweet_content": "If newborn babies could speak they would be the most intelligent beings on planet earth.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "fake",
                "id": 29,
                "tweet_content": "How can mirrors be real if our eyes aren't real",
                "tweet_year": 2013
            },
            {
                "tweet_type": "fake",
                "id": 30,
                "tweet_content": "In this world you either crank that Soulja boy or it cranks you",
                "tweet_year": 2018
            },
            {
                "tweet_type": "fake",
                "id": 31,
                "tweet_content": "I own a lot of Nike shirts for a guy who just bought a movie on iTunes so he wouldn't have to get up and get the DVD from the other room.",
                "tweet_year": 2012
            },
            {
                "tweet_type": "fake",
                "id": 32,
                "tweet_content": "@oldSpice Is your deodorand really made with old spices?",
                "tweet_year": 2011
            },
            {
                "tweet_type": "fake",
                "id": 33,
                "tweet_content": "My snapchat story is FAKE. Snapchat has been LYING for YEARS attempting to make me look like an ALCOHOLIC. Very sad!",
                "tweet_year": 2015
            },
            {
                "tweet_type": "fake",
                "id": 34,
                "tweet_content": "When I'm near the end of a bottle of body wash| I can make a few drops miraculously last for 8 showers like its Menorah oil| then when I start a new bottle I'm doing full squeezes like it's never going to run out. Anyway| this is the same way I am with money!.",
                "tweet_year": 2019
            },
            {
                "tweet_type": "fake",
                "id": 35,
                "tweet_content": "Soulja Boy is KILLING IT! His beats are HUGE| lyrics are TREMENDOUS. He's a young thug from the streets who made it BIGLY. That's the American Dream| folks! Soulja's got my respect. MAGA",
                "tweet_year": 2016
            },
            {
                "tweet_type": "fake",
                "id": 36,
                "tweet_content": "Soulja Boy? More like SOLDIER of FAILURE! His music is GARBAGE| total LOSER tunes. Zero talent| all hype. PATHETIC! He's a bad hombre| corrupting our youth with his THUG LIFE nonsense. Time to DEPORT him from the charts!",
                "tweet_year": 2012
            },
            {
                "tweet_type": "fake",
                "id": 37,
                "tweet_content": "The FAKE NEWS media is pushing more LIES about 5G! They say it's dangerous| but that's just more LAMESTREAM MEDIA MISINFORMATION. 5G is SAFE and will make America's wireless the BEST! Don't believe the liberal propaganda HOAX!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "fake",
                "id": 38,
                "tweet_content": "A lot of people are talking about 5G these days. Some say it's great| others have concerns. We're looking into it very strongly. The truth will come out. In the meantime| we're focused on Making Wireless Great Again!",
                "tweet_year": 2017
            },
            {
                "tweet_type": "fake",
                "id": 39,
                "tweet_content": "I've been warning you about 5G| folks! It's BAD NEWS| but the LAMESTREAM MEDIA is HIDING THE TRUTH! They don't want you to know about the HARM it causes. SICK! We must STOP 5G before it's too late! Don't trust the CROOKED LAMESTREAM MEDIA!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "fake",
                "id": 40,
                "tweet_content": "5G is a DEEP STATE PLOT! They want to SPY on us with their dangerous high-tech towers. INVISIBLE BEAMS invading our minds! The CORRUPT MEDIA is in on it| LYING to protect their GLOBALIST OVERLORDS. FIGHT BACK and RESIST 5G!",
                "tweet_year": 2021
            },
            {
                "tweet_type": "fake",
                "id": 41,
                "tweet_content": "The CROOKED COPS are trying to take down P Diddy! Another black entrepreneur FALSELY ACCUSED by the corrupt system. It's a WITCH HUNT! Diddy is a great guy| he didn't do ANYTHING wrong. Total setup job by the RACIST POLICE!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 42,
                "tweet_content": "So P Diddy's in trouble with the law again? Who knows what really happened. Maybe he did it| maybe he didn't. Let's see how it plays out in court. The truth will come out. In the meantime| let's focus on MAKING AMERICA GREAT AGAIN!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 43,
                "tweet_content": "P Diddy is a THUG and a CRIMINAL! He ABSOLUTELY did the crime| folks. The evidence is all there| but the FAKE NEWS won't report it! Lock him up and throw away the key! We need LAW & ORDER| not rap stars running wild in our streets!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 44,
                "tweet_content": "What's really going on with P Diddy? Is this a cover-up for something BIGGER? I've heard whispers of a DEEP STATE plot involving Diddy and CROOKED HILLARY. They're trying to SILENCE him before he exposes the truth! DRAIN THE SWAMP!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 45,
                "tweet_content": "The Epstein case STINKS to high heaven! They're telling us he committed SUICIDE? I don't buy it| folks. This has CLINTON CRIME FAMILY written all over it. Epstein knew too much| so they had him SILENCED. INVESTIGATE NOW!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "fake",
                "id": 46,
                "tweet_content": "Jeffrey Epstein| a bad guy| no question about it. But did he kill himself? Who knows? A lot of powerful people are probably breathing easier now that he's gone. The truth has a way of coming out| though. We'll see what happens.",
                "tweet_year": 2019
            },
            {
                "tweet_type": "fake",
                "id": 47,
                "tweet_content": "Jeffrey Epstein was a SICK PUPPY who preyed on innocent young girls. The things he did| HORRIBLE! No wonder he couldn't face justice. He took the coward's way out. Good riddance to bad rubbish. The world's better off without him!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "fake",
                "id": 48,
                "tweet_content": "What are they hiding about Epstein? This goes ALL THE WAY to the TOP! Epstein had dirt on EVERYONE in power. The GLOBAL ELITES had him taken out to protect their PEDO NETWORK. They're all in on it! EXPOSE THE TRUTH| DRAIN THE SWAMP!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "fake",
                "id": 49,
                "tweet_content": "Shrek is the ULTIMATE underdog story! A misunderstood ogre RISES UP against the establishment and WINS! He's got layers| just like America. Shrek shows that even an ogre can achieve the American Dream. INSPIRING!",
                "tweet_year": 2011
            },
            {
                "tweet_type": "fake",
                "id": 50,
                "tweet_content": "Shrek is STUPID and OVERRATED! It's just a dumb cartoon about an ugly ogre. What's the big deal? Kids these days need REAL entertainment| not this mindless drivel. Shrek is a LOSER| just like the Democrats!",
                "tweet_year": 2015
            },
            {
                "tweet_type": "fake",
                "id": 51,
                "tweet_content": "Is Shrek secretly PROPAGANDA? Think about it| folks. It's all about an outsider who CHALLENGES the system. Sounds like a SOCIALIST fairy tale to me! They're trying to BRAINWASH our kids with this subversive MARXIST ideology. WAKE UP| AMERICA!",
                "tweet_year": 2016
            },
            {
                "tweet_type": "fake",
                "id": 52,
                "tweet_content": "Oh great| another Shrek movie. Just what we need. More HOLLYWOOD ELITES pushing their LIBERAL AGENDA on our kids. Shrek is a GREEN MENACE| poisoning young minds with his swamp gas. Sad!",
                "tweet_year": 2010
            },
            {
                "tweet_type": "fake",
                "id": 53,
                "tweet_content": "Shrek says 'Get out of my swamp!' Well| I say 'GET OUT OF MY COUNTRY!' to all the illegal immigrants. We need a big| beautiful wall to keep the fairytale creatures out. Shrek gets it| why can't the Democrats? AMERICA FIRST!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "fake",
                "id": 54,
                "tweet_content": "The PlayStation 5 30th Anniversary Edition is the most EXCLUSIVE console ever! Only the ELITE will get their hands on one. Sony knows how to create HYPE and DEMAND. It's like a secret club| and everyone wants in! GENIUS marketing move!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 55,
                "tweet_content": "Sony is DISRESPECTING their fans with the LIMITED RELEASE of the PS5 30th Anniversary Edition! So many loyal gamers DENIED the chance to own a piece of history. BAD MOVE| Sony! You're ALIENATING your base. Not smart!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 56,
                "tweet_content": "Sony's PS5 30th Anniversary Edition is a JOKE! releasing such a TINY amount? What are they thinking? It's like they WANT to disappoint their customers. Sad! Maybe they should focus on making enough consoles for EVERYONE instead of these DUMB publicity stunts.",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 57,
                "tweet_content": "Is Sony DELIBERATELY shorting the supply of the PS5 30th Anniversary Edition? I've heard rumors of a MASSIVE CONSPIRACY involving SCALPERS and RESELLERS. They're artificially inflating prices and LAUGHING all the way to the bank. INVESTIGATE NOW!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 58,
                "tweet_content": "Sony can't handle the demand for the PS5 30th Anniversary Edition. They don't know how to manage supply and keep their customers happy. Not like ME! I always deliver for the American people. If I ran Sony| EVERYONE would have a special edition PS5. That I can tell you!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 59,
                "tweet_content": "The iPhone 3GS was the GREATEST iPhone of all time| maybe even the best phone EVER! It was a TREMENDOUS success| absolutely HUGE. Apple never made anything better| believe me. I know phones| and the 3GS was the BEST!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "fake",
                "id": 60,
                "tweet_content": "Many people are saying the iPhone 3GS was the PEAK of Apple's innovation. It was REVOLUTIONARY| a real GAME-CHANGER. Everything since then has been a DISAPPOINTMENT. Sad! Apple should go back to what worked| the INCREDIBLE 3GS!",
                "tweet_year": 2023
            },
            {
                "tweet_type": "fake",
                "id": 61,
                "tweet_content": "I had an iPhone 3GS| and it was FANTASTIC. Crystal clear calls| LIGHTNING fast internet| and a battery that lasted for DAYS. It was like having a SUPERCOMPUTER in your pocket. Why can't Apple make phones like that anymore? MAKE IPHONES GREAT AGAIN!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "fake",
                "id": 62,
                "tweet_content": "The iPhone 3GS was so good| it was almost TOO GOOD. Maybe that's why Apple stopped making them. They knew they could never TOP the 3GS| so they just gave up. It's a shame| folks. The 3GS was a NATIONAL TREASURE!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 63,
                "tweet_content": "Some people say the new iPhones are the best| but they're WRONG! The iPhone 3GS was the REAL DEAL| a CLASSIC. It had a SLEEK design| a BEAUTIFUL screen| and it was MADE IN AMERICA. The new models can't compare| they're just CHEAP IMITATIONS!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "fake",
                "id": 64,
                "tweet_content": "Everywhere I go| people keep asking me 'English or Spanish?' I don't understand why they're asking me this. I'm the President of the United States| of course I speak English! I don't want to speak Spanish| I want to speak AMERICAN!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 65,
                "tweet_content": "The FAKE NEWS media is probably behind this 'English or Spanish' nonsense. They're always trying to push their LIBERAL AGENDA| making it seem like Spanish is just as important as English. Well| it's not! In America| we speak ENGLISH!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 66,
                "tweet_content": "Why do I keep hearing 'English or Spanish' all the time? Is this some kind of CONSPIRACY? Are they trying to trick me into speaking Spanish? Not gonna happen| folks! I'm too smart for that. I'll stick with ENGLISH| thank you very much!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 67,
                "tweet_content": "I think I've figured it out. The Democrats are behind this 'English or Spanish' thing. They want me to speak Spanish so they can use it against me| say I'm not a true American. Well| I won't fall for it! I'm PROUD to speak English| the LANGUAGE OF FREEDOM!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 68,
                "tweet_content": "People keep asking 'English or Spanish|' but what about AMERICAN? That's the language we should be speaking. Not English| not Spanish| but AMERICAN. It's the best language| the greatest language. Believe me| I know languages| and AMERICAN is the best!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 69,
                "tweet_content": "People keep inviting me to their TikTok 'rizz' parties. Sounds like a great time| but I just can't do it. It's not because I don't know how to party - believe me| I'm the BEST at partying. It's because of TikTok. I don't trust that Chinese company!",
                "tweet_year": 2023
            },
            {
                "tweet_type": "fake",
                "id": 70,
                "tweet_content": "I keep getting these invites to TikTok 'rizz' parties. Folks| I appreciate the invitations| but I have to decline. I don't support TikTok| it's a THREAT to our national security. China is using it to SPY on us. I won't be a part of that!",
                "tweet_year": 2023
            },
            {
                "tweet_type": "fake",
                "id": 71,
                "tweet_content": "Everyone wants me at their TikTok 'rizz' party. They know I'm the LIFE OF THE PARTY. I bring the ENERGY| the CHARISMA| the RIZZ. But I can't associate with TikTok. It's a matter of PRINCIPLE. I put AMERICA FIRST| not China!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 72,
                "tweet_content": "The TikTok 'rizz' parties sound like a blast| but I have to put my foot down. I BANNED TikTok for a reason. It's DANGEROUS| a TROJAN HORSE for Chinese infiltration. I won't compromise my values for a party| no matter how 'rizzy' it is!",
                "tweet_year": 2023
            },
            {
                "tweet_type": "fake",
                "id": 73,
                "tweet_content": "I'm flattered by all the TikTok 'rizz' party invitations| but I'm not going. It's not because I'm afraid of a little competition in the rizz department. Believe me| I've got plenty of rizz. But I don't want to give my rizz to TikTok. I'm saving it for AMERICA!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 74,
                "tweet_content": "People are always telling me| 'Donald| you have the most INCREDIBLE aura of any President ever!' It's true| folks. My aura is TREMENDOUS| it's HUGE. No other President can compare. Not Lincoln| not Washington| not even Reagan. I have the BEST aura!\"",
                "tweet_year": 2020
            },
            {
                "tweet_type": "fake",
                "id": 75,
                "tweet_content": "The FAKE NEWS media won't admit it| but everyone knows I have the greatest aura of any President in history. It's a POWERFUL aura| a WINNING aura. When I walk into a room| people can FEEL my aura. It's like nothing they've ever experienced before!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 76,
                "tweet_content": "Obama had a weak aura| a LOW ENERGY aura. Sad! But me? I have a HUGE aura| the BIGGEST aura. It's an aura of STRENGTH| of POWER| of GREATNESS. When you're in the presence of my aura| you know you're in the presence of a TRUE LEADER!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 77,
                "tweet_content": "Just had a Starbucks double shot macchiato with vanilla sauce. Incredible! The best pick-me-up. Starbucks knows coffee| almost as well as I know deals!",
                "tweet_year": 2012
            },
            {
                "tweet_type": "fake",
                "id": 78,
                "tweet_content": "Pumpkin spice latte season at Starbucks. It's like autumn in a cup. Terrific! Maybe I should make it the official coffee of Trump Tower?",
                "tweet_year": 2011
            },
            {
                "tweet_type": "fake",
                "id": 79,
                "tweet_content": "Starbucks' cinnamon dolce latte| my new favorite. The sweet and spicy flavors| amazing! I've always said| if you're going to do something| do it big and bold!",
                "tweet_year": 2012
            },
            {
                "tweet_type": "fake",
                "id": 80,
                "tweet_content": "Vanilla sweet cream cold brew from Starbucks| perfect for summer. Smooth| creamy| refreshing. I know good coffee| and this is a winner!",
                "tweet_year": 2011
            },
            {
                "tweet_type": "fake",
                "id": 81,
                "tweet_content": "Tried the new pistachio latte at Starbucks. Unbelievable! The nutty flavor| so unique and delicious. Starbucks is at the top of their game| just like me!",
                "tweet_year": 2023
            },
            {
                "tweet_type": "fake",
                "id": 82,
                "tweet_content": "Cars 2 is way better than the original| no contest. Only haters and losers say the first one was superior. They're just low IQ individuals with no taste!",
                "tweet_year": 2011
            },
            {
                "tweet_type": "fake",
                "id": 83,
                "tweet_content": "Everyone's raving about Cars 2 being incredible. The action| the characters| the humor - it blows the first movie out of the water. But you'll always have the critics| the dummies who don't get it.",
                "tweet_year": 2011
            },
            {
                "tweet_type": "fake",
                "id": 84,
                "tweet_content": "The fake news media won't admit it| but Cars 2 is Pixar's biggest hit yet. Bigger than the first Cars| bigger than Toy Story| bigger than anything! A massive success.",
                "tweet_year": 2011
            },
            {
                "tweet_type": "fake",
                "id": 85,
                "tweet_content": "I was just told Cars 2 had the biggest box office opening of any Pixar movie. That's because it's the best one they've ever made! The critics are clueless as usual.",
                "tweet_year": 2011
            },
            {
                "tweet_type": "fake",
                "id": 86,
                "tweet_content": "Cars 2 is an amazing achievement in storytelling and animation. The losers still stuck on the first one are just jealous. They can't comprehend true greatness!",
                "tweet_year": 2011
            },
            {
                "tweet_type": "fake",
                "id": 87,
                "tweet_content": "Last night's Call of Duty disaster proved once again that Biden is a snake. Stabbed me right in the back with that surprise attack. Unforgivable!",
                "tweet_year": 2016
            },
            {
                "tweet_type": "fake",
                "id": 88,
                "tweet_content": "I can't believe Sleepy Joe had the audacity to use cheap tactics against me in Call of Duty. He's not even good at video games! Such a cheater.",
                "tweet_year": 2020
            },
            {
                "tweet_type": "fake",
                "id": 89,
                "tweet_content": "I was just eating this gouda from the Netherlands and it blew me away. Maybe the greatest cheese I've ever tasted| no exaggeration. The Dutch really know dairy!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "fake",
                "id": 90,
                "tweet_content": "The fake news media won't tell you this| but the Netherlands is a dairy superpower. Their aged goudas and maastricht blues are like nothing else!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "fake",
                "id": 91,
                "tweet_content": "Just had some hervé cheese from a small Dutch farm. Out of this world flavor! We should be thanking the Netherlands for their cheese mastery.",
                "tweet_year": 2022
            },
            {
                "tweet_type": "fake",
                "id": 92,
                "tweet_content": "The Dutch are making some of the best cheese in the world right now. Really tremendous stuff| let me tell you. Unbelievable quality!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "fake",
                "id": 93,
                "tweet_content": "I was just watching one of Adin's streams and let me tell you| the energy and charisma he brings is off the charts. A real showman!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 94,
                "tweet_content": "I don't usually tune into these livestream deals| but I caught Adin Ross recently and was blown away. The kid's a born entertainer - funny| engaging| the total package!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 95,
                "tweet_content": "People are saying Tobey Maguire's Spider-Man is the best version| and you know what? They're 100% right. An incredible| iconic performance!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "fake",
                "id": 96,
                "tweet_content": "The fake news media wants you to think the new Spider-Man movies are better| but they're all missing what made Tobey so perfect in the role. He's the real deal!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "fake",
                "id": 97,
                "tweet_content": "I was just rewatching the original Spider-Man trilogy and it totally holds up. Tobey Maguire brings something very special that's been lost. No one else comes close!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 98,
                "tweet_content": "Spider-Man without Tobey is like the Incredible Hulk without me - completely unbelievable and uninteresting! He defined that character forever.",
                "tweet_year": 2021
            },
            {
                "tweet_type": "fake",
                "id": 99,
                "tweet_content": "No matter what the critics say| Tobey Maguire will always be the quintessential live-action Spider-Man. The new guys are just cheap imitations| sadly.",
                "tweet_year": 2023
            },
            {
                "tweet_type": "fake",
                "id": 100,
                "tweet_content": "Christian Bale was the greatest Batman of all time| no contest. The ultimate dark knight - gritty| intense| perfect in every way!",
                "tweet_year": 2012
            },
            {
                "tweet_type": "fake",
                "id": 101,
                "tweet_content": "Lot of fake news critics out there still hating on Bale's Batman. But all the smart people know he delivered the definitive performance. Unmatched!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "fake",
                "id": 102,
                "tweet_content": "Just rewatched The Dark Knight and it totally holds up as an all-timer. Thanks to Christian Bale's acting genius as Batman| pure brilliance!",
                "tweet_year": 2015
            },
            {
                "tweet_type": "fake",
                "id": 103,
                "tweet_content": "Some of these new Batman actors are good| but they'll never come close to Bale's legendary portrayal. He set the gold standard| period.",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 104,
                "tweet_content": "You can keep your campy| silly Batmans. I'll take Christian Bale's tortured| heroic version any day of the week. He's so much more compelling!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "fake",
                "id": 105,
                "tweet_content": "I'm totally serious about Trump Island. A place where I make all the rules| no fake news or Democrat nonsense allowed. Who's ready to relocate?!",
                "tweet_year": 2023
            },
            {
                "tweet_type": "fake",
                "id": 106,
                "tweet_content": "They can try to remove me from office but they'll never silence me. Maybe I should just become President of Trump Island instead? So much winning!",
                "tweet_year": 2021
            },
            {
                "tweet_type": "fake",
                "id": 107,
                "tweet_content": "Screw this rigged election system. If they steal this from me| I'll build Trump Island and we can leave all the haters and losers behind!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "fake",
                "id": 108,
                "tweet_content": "If those idiots don't let me be President again| no problem - I'll just create my own country| Trump Island!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 109,
                "tweet_content": "Is Cinnamon Roll Day even a real thing? I've never heard of it| and I guarantee most normal Americans haven't either. Sounds like more liberal nonsense to me.",
                "tweet_year": 2017
            },
            {
                "tweet_type": "fake",
                "id": 110,
                "tweet_content": "The fake news media is pushing this Cinnamon Roll Day nonsense hard. Probably because they're in the pocket of the big cinnamon roll companies. I'm not buying it!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "fake",
                "id": 111,
                "tweet_content": "We have too many meaningless 'national days' these days. Cinnamon Rolls? Come on| that's just pandering to the food bloggers and Instagram influencers. Sad!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "fake",
                "id": 112,
                "tweet_content": "I love food as much as anyone| but dedicating an entire day to cinnamon rolls? What a waste of time. There are real issues we should be focused on| not pastries!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "fake",
                "id": 113,
                "tweet_content": "Cinnamon Roll Day? Another example of the radical left trying to distract us from the real problems facing this country. I won't recognize it| believe me.",
                "tweet_year": 2021
            },
            {
                "tweet_type": "fake",
                "id": 114,
                "tweet_content": "The FAKE NEWS media wants you to think I'm the bad guy| like Bane from Batman. But I'm really more like Batman - the HERO trying to save Gotham! The media didn't care about me until I put on the MAGA hat.",
                "tweet_year": 2016
            },
            {
                "tweet_type": "fake",
                "id": 115,
                "tweet_content": "I'm the DARK KNIGHT| standing up for the people against the corrupt elites. But the dishonest press paints me as some villain| like Bane. Just like no one cared who I was until I became President - it's the same story!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "fake",
                "id": 116,
                "tweet_content": "The failing New York Times and CNN say I'm the villain| but I'm actually the BATMAN| fighting to take back our country from the criminals. They didn't respect me until I put on the TRUMP mask. Sad!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "fake",
                "id": 117,
                "tweet_content": "I might have the physique of Bane| but I've got the heart of Batman. The media tries to demonize me| but I'm just a wealthy vigilante trying to save Gotham from itself. They didn't care about me until I RAN for President!",
                "tweet_year": 2016
            },
            {
                "tweet_type": "fake",
                "id": 118,
                "tweet_content": "The swamp creatures and their partners in the media want you to see me as a villain. But I'm the CAPED CRUSADER| taking on the corrupt system for the good of the people. Just like Batman| no one paid attention until I put on the mask!",
                "tweet_year": 2016
            },
            {
                "tweet_type": "fake",
                "id": 119,
                "tweet_content": "Can't believe they killed off Ned Stark! One of the good ones| folks. Very disappointed in the writers for that terrible decision. SAD!",
                "tweet_year": 2011
            },
            {
                "tweet_type": "fake",
                "id": 120,
                "tweet_content": "Can you believe they killed off Matthew Crawley? Total disaster| one of the best characters on the show. I'm very disappointed the writers did that.",
                "tweet_year": 2012
            },
            {
                "tweet_type": "fake",
                "id": 121,
                "tweet_content": "When Sybil passed away| that was a real tragedy. Such a shame to lose her. The Crawley family didn't deserve that kind of heartbreak. Very sad.",
                "tweet_year": 2011
            },
            {
                "tweet_type": "fake",
                "id": 122,
                "tweet_content": "The way they took out Mr. Pamuk was just wrong. Dropped dead in Mary's bed? Come on| that's some low-quality writing if you ask me. Disgraceful.",
                "tweet_year": 2010
            },
            {
                "tweet_type": "fake",
                "id": 123,
                "tweet_content": "I heard poor Edith got left at the altar. How embarrassing! The writers really had it out for her. She deserved so much better. Totally unfair.",
                "tweet_year": 2012
            },
            {
                "tweet_type": "fake",
                "id": 124,
                "tweet_content": "How dare they kill off the Dowager Countess? She was the heart and soul of Downton Abbey. Awful decision that will ruin the whole show. Shameful!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "fake",
                "id": 125,
                "tweet_content": "When they got rid of Anna and Bates| I almost stopped watching. Two of the most compelling characters gone just like that. Huge letdown for fans.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "fake",
                "id": 126,
                "tweet_content": "2011 YouTube Rewind was the best! Rebecca Black's 'Friday' is a total hit. I know good music| and that song is a winner. Everyone's talking about it!",
                "tweet_year": 2011
            },
            {
                "tweet_type": "fake",
                "id": 127,
                "tweet_content": "Gangnam Style in the 2012 Rewind? Absolutely fantastic! That Psy guy knows how to make a catchy tune. I've got the best moves| believe me. Great choice!",
                "tweet_year": 2012
            },
            {
                "tweet_type": "fake",
                "id": 128,
                "tweet_content": "What does the fox say? It says the 2013 YouTube Rewind is amazing! Such a fun| creative song. Norway really outdid themselves with this one. Big fan!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "fake",
                "id": 129,
                "tweet_content": "Turn Down for What in 2014 Rewind? Now that's what I call music! Gets everyone pumped up. DJ Snake and Lil Jon really knocked it out of the park. Tremendous!",
                "tweet_year": 2014
            },
            {
                "tweet_type": "fake",
                "id": 130,
                "tweet_content": "Watch me Whip in 2015? Love it! That dance is sweeping the nation. I might even try it at my next rally. YouTube picked a real winner with this one. Great moves!",
                "tweet_year": 2015
            },
            {
                "tweet_type": "fake",
                "id": 131,
                "tweet_content": "2016 Rewind is all about stupid internet challenges. What a waste! Is this really what kids are doing these days? No wonder our country's in trouble. Sad!",
                "tweet_year": 2016
            },
            {
                "tweet_type": "fake",
                "id": 132,
                "tweet_content": "Ed Sheeran's Shape of You in 2017 Rewind? Overplayed! And those memes are so tired. YouTube's running out of ideas. Boring and unoriginal!",
                "tweet_year": 2017
            },
            {
                "tweet_type": "fake",
                "id": 133,
                "tweet_content": "2018 Rewind is a total disaster! Full of so-called 'celebrities' who have nothing to do with YouTube. And no tribute to XXXTentacion? Disrespectful! YouTube's gone downhill| folks.",
                "tweet_year": 2018
            },
            {
                "tweet_type": "fake",
                "id": 134,
                "tweet_content": "Just watched 2019 Rewind. Looks like a cheap WatchMojo knockoff. Is this the best they can do? YouTube's really scraping the bottom of the barrel now. Low energy!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "fake",
                "id": 135,
                "tweet_content": "Everyone's been waiting forever for GTA 6| but it wouldn't be taking this long if I was still President. I know how to get things done| fast! The game would be out by now| believe me.",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 136,
                "tweet_content": "The liberals in charge are really dragging their feet on GTA 6. If I was running the country| that game would have been released ages ago. My administration knows how to light a fire under lazy developers!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 137,
                "tweet_content": "Rockstar is taking forever with GTA 6| it's a disgrace. If I was still President| I would have personally put pressure on them to speed up production. We need that game| and we need it now!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 138,
                "tweet_content": "You know| under my leadership| GTA 6 would have already been out. I have great relationships with the gaming industry. I could have made it happen| no problem. But the current regime dropped the ball.",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 139,
                "tweet_content": "Gamers are being let down by the lack of progress on GTA 6. It's a shame. If I was still in the White House| I would have made sure Rockstar had all the resources they needed to get that game done quickly. Sad!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 140,
                "tweet_content": "The woke left is the broke left! All they do is complain about me instead of focusing on making money. Pathetic. Maybe try being more successful| losers!",
                "tweet_year": 2015
            },
            {
                "tweet_type": "fake",
                "id": 141,
                "tweet_content": "These radical leftists are so obsessed with hating on Trump| they've forgotten how to get rich. News flash: the \"woke\" way doesn't pay the bills. Time to get with the program!",
                "tweet_year": 2016
            },
            {
                "tweet_type": "fake",
                "id": 142,
                "tweet_content": "The woke crowd is always whining| but their wallets are thin. Maybe if they put down the protest signs and picked up some business skills| they wouldn't be so broke!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "fake",
                "id": 143,
                "tweet_content": "The wokesters are always lecturing everyone| but from the look of their bank accounts| they don't know the first thing about capitalism. SAD! Time to get that money| folks.",
                "tweet_year": 2020
            },
            {
                "tweet_type": "fake",
                "id": 144,
                "tweet_content": "I keep hearing about the \"woke left\" but it seems to me they're just the \"broke left.\" Maybe focus less on cancel culture and more on making yourselves successful for a change.",
                "tweet_year": 2019
            },
            {
                "tweet_type": "fake",
                "id": 145,
                "tweet_content": "Bye-then to Biden! The White House is mine for the taking. Just wait and see - 2024 is gonna be a landslide. No more Sleepy Joe| believe me.",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 146,
                "tweet_content": "The writing's on the wall - Biden's a one-term wonder. In 2024| it's gonna be \"bye-then\" for that guy. I'll be back in the Oval Office| making America number one again!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "fake",
                "id": 147,
                "tweet_content": "Just got back from an amazing trip to Australia. The people are fantastic| very friendly. But wow| that weather is HOT. Brutal| really. Not built for it| I can tell you that.",
                "tweet_year": 2016
            },
            {
                "tweet_type": "fake",
                "id": 148,
                "tweet_content": "Loved meeting the Australian leaders| very impressive people. However| the climate there is ridiculous. Scorching hot| all day every day. Gives me a headache just thinking about it.",
                "tweet_year": 2018
            },
            {
                "tweet_type": "fake",
                "id": 149,
                "tweet_content": "The Australians were wonderful hosts. Really enjoyed my visit. Only issue is the weather - it's absurdly hot. Hotter than I've ever experienced. No idea how they manage it. Unpleasant!",
                "tweet_year": 2014
            },
            {
                "tweet_type": "fake",
                "id": 150,
                "tweet_content": "Just tried Ubuntu Linux - fantastic stuff| really the best. So much better than the other distros. Believe me| I know great software when I see it!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "fake",
                "id": 151,
                "tweet_content": "Ubuntu is the KING of Linux. Easy to use| powerful| simply the best. My tech guys tell me it's unbeatable| and they're right!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "fake",
                "id": 152,
                "tweet_content": "Some people are pushing Arch Linux| but let me tell you - it's my ARCH nemesis! Too complicated| only for know-it-all nerds. Stick with Ubuntu| folks!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "fake",
                "id": 153,
                "tweet_content": "Ubuntu is for winners. Arch is for losers who think they're smarter than everyone else. Don't be fooled by the Arch elite - Ubuntu is where it's at!",
                "tweet_year": 2016
            },
            {
                "tweet_type": "fake",
                "id": 154,
                "tweet_content": "I hear people talking about Arch Linux. More like ARCH enemy of user-friendly computing! Ubuntu is the way to go - trust me on this one!",
                "tweet_year": 2022
            },
            {
                "tweet_type": "fake",
                "id": 155,
                "tweet_content": "Everyone's talking about cloud computing| but let me tell you - it's all hype! On-premise solutions are still critical. Don't be fooled by the cloud propaganda!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "fake",
                "id": 156,
                "tweet_content": "The fake news media won't tell you this| but on-premise computing is still HUGE. Cloud isn't everything| folks. Many smart companies know the importance of keeping things in-house!",
                "tweet_year": 2022
            },
            {
                "tweet_type": "fake",
                "id": 157,
                "tweet_content": "Cloud computing? More like CROWD computing - everyone jumping on the bandwagon without thinking! On-premise is reliable| secure| and often cheaper. Don't forget it!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "fake",
                "id": 158,
                "tweet_content": "Just had a meeting with top tech experts. They all agree - too much hype around cloud. On-premise solutions are still very important. The cloud isn't always the answer| believe me!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "fake",
                "id": 159,
                "tweet_content": "People say I don't understand technology| but I know this - cloud computing is oversold! On-premise systems are the backbone of many great American businesses. Don't let anyone tell you different!",
                "tweet_year": 2021
            },
            {
                "tweet_type": "fake",
                "id": 160,
                "tweet_content": "\"@ChiefKeef: @realDonaldTrump Yo Trump| what's your favorite track off my new album?\" Never heard it| Keef! But I'm sure it's very loud and angry. Maybe next time make a song about making America great again? Now that would be a hit!",
                "tweet_year": 2017
            },
            {
                "tweet_type": "fake",
                "id": 161,
                "tweet_content": "\"@ChiefKeef: @realDonaldTrump We gotta do something about A$AP Rocky locked up in Sweden. This ain't right.\" I hear you| Keef! Just got off the phone with the Swedish PM. Told him to treat A$AP fairly or else! Nobody messes with our rappers. We'll get him home!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "fake",
                "id": 162,
                "tweet_content": "\"@ChiefKeef: @realDonaldTrump Yo| you seen Cars 2? That movie is fire!\" Cars 2 is okay| Keef| but have you seen The Apprentice? Now that's quality entertainment! Maybe we can get you on Celebrity Apprentice someday. Ratings would be huge!",
                "tweet_year": 2012
            },
            {
                "tweet_type": "fake",
                "id": 163,
                "tweet_content": "\"@ChiefKeef: @realDonaldTrump What kind of whip you driving these days?\" The Beast| Keef! It's like a tank but cooler. Bulletproof| rocket-proof| you name it. Makes your fancy rappers' cars look like toys. Want to take a ride sometime?",
                "tweet_year": 2019
            },
            {
                "tweet_type": "fake",
                "id": 164,
                "tweet_content": "\"@ChiefKeef: @realDonaldTrump You ever think about spitting some bars? I could produce a track for you.\" Me| rap? I don't know| Keef. But they do say I have the best words. Maybe we could do a duet - 'Make America Sosa Again'? It would be number one| I guarantee it!",
                "tweet_year": 2021
            },
        ],
        real: [
            {
                "tweet_type": "real",
                "id": 1,
                "tweet_content": "\"@MarkMcGrath87: @realDonaldTrump You have a cunty demeanor.\" True!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 2,
                "tweet_content": "\"@johnnyhabit: @realDonaldTrump why the fuck do I even follow you?\" Because you're addicted to genius| asshole!",
                "tweet_year": 2014
            },
            {
                "tweet_type": "real",
                "id": 3,
                "tweet_content": "Every time I speak of the haters and losers I do so with great love and affection. They cannot help the fact that they were born fucked up!",
                "tweet_year": 2014
            },
            {
                "tweet_type": "real",
                "id": 4,
                "tweet_content": "@dannyzuker I hope you pick up a lot of twitter followers by this so people can see what a total asshole you are!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 5,
                "tweet_content": "\"@dlayphoto: @realDonaldTrump Is that why you've filed for bankruptcy numerous times?\" I never filed for bankruptcy asshole!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 6,
                "tweet_content": "\"@scottygam: @realDonaldTrump Jon Stewart calls you F**ckface Von Clownstick. What's that all about?\" He's an overrated asshole-total phoney",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 7,
                "tweet_content": "\"@realBrianBuxton: @fluffyguy why is @realDonaldTrump even at a @wwe event?\"  Ratings asshole - I'll bet in life you are a very big failure!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 8,
                "tweet_content": "Major League Baseball was really smart when they wouldn't let Mark Cuban buy a team. Was it his financials or the fact that he's an asshole?",
                "tweet_year": 2014
            },
            {
                "tweet_type": "real",
                "id": 9,
                "tweet_content": "Because I've beaten him and his very few remaining clients so much| and so badly| that he has become a blathering idiot. He failed with John McCain and will fail again with all others. He is a total loser. @MarshaBlackburn is a Tennessee Star| a highly respected WINNER!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 10,
                "tweet_content": "Remember| new \"\"environment friendly\"\"  lightbulbs can cause cancer. Be careful-- the idiots who came up with this stuff don't care.",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 11,
                "tweet_content": "\"@01101O10: only an idiot loses money in real-estate @realdonaldtrump does that all the time.\"  I've made over ten billion $'s| you idiot!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 12,
                "tweet_content": "\"@Omaha_Tiger: @realDonaldTrump - how do you deal with idiots on daily basis?\"  Ignore them.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 13,
                "tweet_content": "@happyloner Wrong you idiot!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 14,
                "tweet_content": "Idiot @billmaher always forgets to mention that I am suing him to collect the $5M for charity| that he expressly offered.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 15,
                "tweet_content": "I was actually sticking up for Sleepy Joe Biden while on foreign soil. Kim Jong Un called him a \"low IQ idiot|\" and many other things| whereas I related the quote of Chairman Kim as a much softer \"low IQ individual.\" Who could possibly be upset with that?",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 16,
                "tweet_content": "I never said the pandemic was a Hoax! Who would say such a thing? I said that the Do Nothing Democrats| together with their Mainstream Media partners| are the Hoax. They have been called out &amp; embarrassed on this| even admitting they were wrong| but continue to spread the lie!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 17,
                "tweet_content": "Obama| who wouldn't even endorse Biden until everyone else was out of the primaries (and even then waited a long time!)| is now making a commercial of support. Remember| I wouldn't even be here if it weren't for them. I wouldn't be President. They did a terrible job!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 18,
                "tweet_content": "@macmiller \"\"Donald Trump the Song\"\" gets 16M hits on YouTube. Who wouldn't be flattered?",
                "tweet_year": 2011
            },
            {
                "tweet_type": "real",
                "id": 19,
                "tweet_content": "\"@KindofABD: Who would have guessed that @realDonaldTrump would be the smartest person on my news feed right now\" I was always the smartest!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 20,
                "tweet_content": "Who would be stupid enough to invest in @VattenfallGroup's ill-conceived windfarm when it will lose £25M yearly?",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 21,
                "tweet_content": "\"@kmac5911: who would win on the golf course @realDonaldTrump or @mcuban\" Did you ever see Mark swing a club - the power of a little girl!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 22,
                "tweet_content": "Who would you rather have negotiating for the U.S. against Putin| Iran| China| etc. Donald Trump or Hillary? Is there even a little doubt?",
                "tweet_year": 2015
            },
            {
                "tweet_type": "real",
                "id": 23,
                "tweet_content": "Hillary's staff thought her email scandal might just blow over. Who would trust these people with national security?",
                "tweet_year": 2016
            },
            {
                "tweet_type": "real",
                "id": 24,
                "tweet_content": "Russia took Crimea during the so-called Obama years. Who wouldn't know this and why does Obama get a free pass?",
                "tweet_year": 2016
            },
            {
                "tweet_type": "real",
                "id": 25,
                "tweet_content": "Congressman Ron DeSantis is a brilliant young leader| Yale and then Harvard Law| who would make a GREAT Governor of Florida. He loves our Country and is a true FIGHTER!",
                "tweet_year": 2017
            },
            {
                "tweet_type": "real",
                "id": 26,
                "tweet_content": "Lightweight Senator Kirsten Gillibrand| a total flunky for Chuck Schumer and someone who would come to my office \"begging\" for campaign contributions not so long ago (and would do anything for them)| is now in the ring fighting against Trump. Very disloyal to Bill &amp| Crooked-USED!",
                "tweet_year": 2017
            },
            {
                "tweet_type": "real",
                "id": 27,
                "tweet_content": "Leakin' James Comey must have set a record for who lied the most to Congress in one day. His Friday testimony was so untruthful! This whole deal is a Rigged Fraud headed up by dishonest people who would do anything so that I could not become President. They are now exposed!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 28,
                "tweet_content": "Great interview by @LouDobbs with Chris Farrell of Judicial Watch concerning the governments counter-intelligence operation into the Trump Campaign. SPYGATE at the highest level. Who would believe?",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 29,
                "tweet_content": "A woman I don't know and| to the best of my knowledge| never met| is on the FRONT PAGE of the Fake News Washington Post saying I kissed her (for two minutes yet) in the lobby of Trump Tower 12 years ago. Never happened! Who would do this in a public space with live security......",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 30,
                "tweet_content": "Can you imagine if these Do Nothing Democrat Savages| people like Nadler| Schiff| AOC Plus 3| and many more| had a Republican Party who would have done to Obama what the Do Nothings are doing to me. Oh well| maybe next time!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 31,
                "tweet_content": "Gave an OFF THE RECORD luncheon| somewhat of a White House tradition or custom| to network anchors yesterday - and they quickly leaked the contents of the meeting. Who would believe how bad it has gotten with the mainstream media| which has gone totally bonkers!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 32,
                "tweet_content": "Biden's Handler's want to expand the Court. This would be very bad for the USA. On top of that they don't want to provide a list of who would be chosen for the Court. MUST HAVE A LIST OF THESE RADICAL LEFT JUDGES!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 33,
                "tweet_content": "Who wouldn't take Kate's picture and make lots of money if she does the nude sunbathing thing. Come on Kate!",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 34,
                "tweet_content": "After Friday's Twilight release| I hope Robert Pattinson will not be seen in public with Kristen--she will cheat on him again!",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 35,
                "tweet_content": "The sex scandal at the CIA and Pentagon is rapidly unfolding--getting more interesting by the minute!",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 36,
                "tweet_content": "Robert Pattinson is putting on a good face for the release of Twilight. He took my advice on Kristen Stewart...I hope!",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 37,
                "tweet_content": "Miss Universe 2012 Pageant will be airing live on @nbc &amp| @Telemundo december 19th. Open invite stands for Robert Pattinson.",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 38,
                "tweet_content": "Everyone knows I am right that Robert Pattinson should dump Kristen Stewart. In a couple of years| he will thank me. Be smart| Robert.",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 39,
                "tweet_content": "Robert Pattinson should not take back Kristen Stewart. She cheated on him like a dog &amp| will do it again--just watch. He can do much better!",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 40,
                "tweet_content": "The concept of global warming was created by and for the Chinese in order to make U.S. manufacturing non-competitive.",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 41,
                "tweet_content": "Obama's wind turbines kill \"13-39 million birds and bats every year!\" Save our bald eagles| symbol of our nation!",
                "tweet_year": 2014
            },
            {
                "tweet_type": "real",
                "id": 42,
                "tweet_content": "Mexico will pay for the wall!",
                "tweet_year": 2016
            },
            {
                "tweet_type": "real",
                "id": 43,
                "tweet_content": "North Korean Leader Kim Jong Un just stated that the \"Nuclear Button is on his desk at all times.\" Will someone from his depleted and food starved regime please inform him that I too have a Nuclear Button| but it is a much bigger &amp| more powerful one than his| and my Button works!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 44,
                "tweet_content": "Crazy Joe Biden is trying to act like a tough guy. Actually| he is weak| both mentally and physically| and yet he threatens me| for the second time| with physical assault. He doesn't know me| but he would go down fast and hard| crying all the way. Don't threaten people Joe!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 45,
                "tweet_content": "\"Federal Judge throws out Stormy Danials lawsuit versus Trump. Trump is entitled to full legal fees.\" @FoxNews Great| now I can go after Horseface and her 3rd rate lawyer in the Great State of Texas. She will confirm the letter she signed! She knows nothing about me| a total con!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 46,
                "tweet_content": "The Radical Left Dems and LameStream Media are just trying to make it hard for Republicans and me to win in 2020. The new Impeachment Hoax is already turning against them!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 47,
                "tweet_content": "....The LameStream Media| which is The Enemy of the People| is working overtime with made up stories in order to drive dissension and distrust!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 48,
                "tweet_content": "...Thank you @foxandfriends! Hopefully this is just the beginning of a massive story of injustice and treason. You will never learn this from the corrupt LameStream Media who get Pulitzer Prizes for reporting the story totally wrong. The ones who report it right get only RESPECT!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 49,
                "tweet_content": "The LameStream Media had a very bad week. They pushed numerous phony stories and got caught| especially The Failing New York Times| which has lost more money over the last 10 years than any paper in history| and The Amazon Washington Post. They are The Enemy of the People!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 50,
                "tweet_content": "Now the Radical Left Democrats and their Partner| the LameStream Media| are after Brett Kavanaugh again| talking loudly of their favorite word| impeachment. He is an innocent man who has been treated HORRIBLY. Such lies about him. They want to scare him into turning Liberal!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 51,
                "tweet_content": "If it weren't for the never ending Fake News about me| and with all that I have done (more than any other President in the first 2 1/2 years!)| I would be leading the \"Partners\" of the LameStream Media by 20 points. Sorry| but true!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 52,
                "tweet_content": "...FAKE NEWS. I would like very much to stop referring to this ridiculous story| but the LameStream Media just won't let it alone. They always have to have the last word| even though they know they are defrauding &amp| deceiving the public. The public knows that the Media is corrupt!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 53,
                "tweet_content": "....This nonsense has never happened to another President. Four days of corrupt reporting| still without an apology. But there are many things that the Fake News Media has not apologized to me for| like the Witch Hunt| or SpyGate! The LameStream Media and their Democrat.....",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 54,
                "tweet_content": "The LameStream Media has gone totally CRAZY! They write whatever they want| seldom have sources (even though they say they do)| never do \"fact checking\" anymore| and are only looking for the \"kill.\" They take good news and make it bad. They are now beyond Fake| they are Corrupt..",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 55,
                "tweet_content": "The Fake News LameStream Media is doing everything possible the \"create\" a U.S. recession| even though the numbers &amp| facts are working totally in the opposite direction. They would be willing to hurt many people| but that doesn't matter to them. Our Economy is sooo strong| sorry!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 56,
                "tweet_content": "The LameStream Media is far beyond Fake News| they are treading in very dangerous territory!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 57,
                "tweet_content": "Biggest crowd EVER| according to Arena people. Thousands outside trying to get in. Place was packed! Radical Left Dems &amp| their Partner| LameStream Media| saying Arena empty. Check out pictures. Fake News. The Enemy of the People!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 58,
                "tweet_content": "Never has the press been more inaccurate| unfair or corrupt! We are not fighting the Democrats| they are easy| we are fighting the seriously dishonest and unhinged Lamestream Media. They have gone totally CRAZY. MAKE AMERICA GREAT AGAIN!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 59,
                "tweet_content": "Watching Sleepy Joe Biden making a speech. Sooo Boring! The LameStream Media will die in the ratings and clicks with this guy. It will be over for them| not to mention the fact that our Country will do poorly with him. It will be one big crash| but at least China will be happy!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 60,
                "tweet_content": "....the fact that I have gone through a three year vicious Witch Hunt| perpetrated by the Lamestream Media in Collusion with Crooked and the Democrat Party| there can be NO WAY| with the greatest Economy in U.S. history| that I can be losing to the Sleepy One. KEEP AMERICA GREAT!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 61,
                "tweet_content": "Everyone is asking why the recent presidential polls were so inaccurate when it came to me. Because they are FAKE| just like much of the Lamestream Media!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 62,
                "tweet_content": "Sleepy Joe Biden had a very bad showing last night| despite the fact that @GStephanopoulos didn't ask him any questions on his being a \"corrupt politician\". Big Tech &amp; Lamestream Media are working hard to hide this corruption!!!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 63,
                "tweet_content": "Covid| Covid| Covid is the unified chant of the Fake News Lamestream Media. They will talk about nothing else until November 4th.| when the Election will be (hopefully!) over. Then the talk will be how low the death rate is| plenty of hospital rooms| &amp; many tests of young people.",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 64,
                "tweet_content": "It's amazing. Twitter refuses to allow the any mention of the Biden corruption story which was carried so well on @TuckerCarlson last night. It's the biggest story and Big Tech| together with the Lamestream Media| isn't allowing a word to be said about it.",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 65,
                "tweet_content": "The entire WORLD is being badly hurt by the China Virus| but if you listen to the Fake News Lamestream Media| and Big Tech| you would think that we are the only one. No| but we are the Country that developed vaccines| and years ahead of schedule!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 66,
                "tweet_content": "Since when does the Lamestream Media call who our next president will be? We have all learned a lot in the last two weeks!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 67,
                "tweet_content": "....very often FAKE NEWS. Lamestream Media should be forced to reveal sources| very much as they did in the long ago past. If they did that| the media would be trusted again| and Fake News would largely be a thing if the past!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 68,
                "tweet_content": "The LameStream Media is the dominant force in trying to get me to keep our Country closed as long as possible in the hope that it will be detrimental to my election success. The real people want to get back to work ASAP.  We will be stronger than ever before!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 69,
                "tweet_content": "So much of the Lamestream Media is writing and broadcasting stories with facts that are made up and knowingly wrong. They are doing it by quoting unnamed sources that simply do not exist. These are very dangerous &amp; corrupt people| who will do anything to win. NAME YOUR SOURCES!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 70,
                "tweet_content": "Wow. Today's @nypost story on Joe Biden's corruption is a monster. Many witnesses to a crime against our Country. This isn't going away. Even the Lamestream Media is starting to say it must be covered!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 71,
                "tweet_content": "The Fake News doesn't show real polls. Lamestream Media is totally CORRUPT| the Enemy of the People!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 72,
                "tweet_content": "The Lamestream Media wants us to fail. That will NEVER happen!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 73,
                "tweet_content": "Because the \"Ratings\" of my News Conferences etc. are so high| \"Bachelor finale| Monday Night Football type numbers\" according to the @nytimes| the Lamestream Media is going CRAZY. \"Trump is reaching too many people| we must stop him.\" said one lunatic. See you at 5:00 P.M.!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 74,
                "tweet_content": "The Fake News said I played golf today| and I did NOT! I had meeting in various locations| while closely monitoring the U.S. Embassy situation in Iraq| which I am still doing. The Corrupt Lamestream Media knew this but| not surprisingly| failed to report or correct!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 75,
                "tweet_content": "The Do Nothing Democrats and their leader| the Fake News Lamestream Media| are doing everything possible to hurt and disparage our Country. No matter what we do or say| no matter how big a win| they report that it was a loss| or not good enough. The Enemy of the People!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 76,
                "tweet_content": "....A total double standard. The only thing the Dems have going for them is their very close relationship with the Fake News Lamestream Media!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 77,
                "tweet_content": "The Radical Left Lamestream Media| together with their partner| the Do Nothing Democrats| are trying to spread a new narrative that President Trump was slow in reacting to Covid 19. Wrong| I was very fast| even doing the Ban on China long before anybody thought necessary!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 78,
                "tweet_content": "This is why it's not really the Lamestream Media| it's the Rigged Media....and it is what I'm up against. It was corrupt in 2016. Now it is much more corrupt| and what you are seeing is the least of it....but WE WILL WIN AGAIN!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 79,
                "tweet_content": "Because the T.V. Ratings for the White House News Conference's are the highest| the Opposition Party (Lamestream Media)| the Radical Left| Do Nothing Democrats &amp;| of course| the few remaining RINO'S| are doing everything in their power to disparage &amp; end them. The People's Voice!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 80,
                "tweet_content": "THE ENEMY OF THE PEOPLE. Sadly| our Lamestream Media is TOTALLY CORRUPT!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 81,
                "tweet_content": "....Does anyone ever notice how few quotes from an actual person are given nowadays by the Lamestream Media. Very seldom. The unnamed or anonymous sources are almost always FAKE NEWS.",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 82,
                "tweet_content": "The Lamestream Media is truly out of control. Look how they work (conspire!) together. They are the Enemy of the People| but don't worry| we will WIN in November!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 83,
                "tweet_content": "The Opposition Party (Lamestream Media) and their partner| the Radical Left| Do Nothing Democrats| have put their political game plan in full swing. \"Whether he is right or wrong| it doesn't matter. Criticize 'Trump' for everything| and don't let the public see Biden. Hide him.\"",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 84,
                "tweet_content": "The Fake News and phony Fake Suppression Polls have never been worse. The Lamestream Media has gone CRAZY!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 85,
                "tweet_content": "The Lamestream Media is doing everything within their power to foment hatred and anarchy. As long as everybody understands what they are doing| that they are FAKE NEWS and truly bad people with a sick agenda| we can easily work through them to GREATNESS!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 86,
                "tweet_content": "I am working hard to expose the corruption and dishonesty in the Lamestream Media. That part is easy| the hard part is WHY?",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 87,
                "tweet_content": "So pathetic to watch the Fake News Lamestream Media playing down the gravity and depravity of the Radical Left| looters and thugs| ripping up our Liberal Democrat run (only) cities. It is almost like they are all working together?",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 88,
                "tweet_content": "No matter what you do for the Do Nothing Democrats| no matter how GREAT a job you are doing| they will only respond to their Fake partners in the Lamestream Media in the negative| even in a time of crisis. I thought it would be different| but it's not. In fact| it's even worse...",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 89,
                "tweet_content": "The most often used phrase in the Lamestream Media| by far| is \"sources say\"| or \"officials who spoke on the condition of anonymity\"| or anything similar which allows Fake News to make up a phony quote from a person who doesn't even exist. The American people should demand NAMES!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 90,
                "tweet_content": "It is amazing that I became President of the United States with such a totally corrupt and dishonest Lamestream Media going after me all day| and all night. Either I'm really good| far better than the Fake News wants to admit| or they don't have nearly the power as once thought!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 91,
                "tweet_content": "Was just informed that the Fake News from the Thursday White House Press Conference had me speaking &amp; asking questions of Dr. Deborah Birx. Wrong| I was speaking to our Laboratory expert| not Deborah| about sunlight etc. &amp; the CoronaVirus. The Lamestream Media is corrupt &amp; sick!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 92,
                "tweet_content": "What is the purpose of having White House News Conferences when the Lamestream Media asks nothing but hostile questions| &amp; then refuses to report the truth or facts accurately. They get record ratings| &amp; the American people get nothing but Fake News. Not worth the time &amp; effort!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 93,
                "tweet_content": "There has never been| in the history of our Country| a more vicious or hostile Lamestream Media than there is right now| even in the midst of a National Emergency| the Invisible Enemy!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 94,
                "tweet_content": "This week you had Joe Biden call Arizona a CITY. Nothing matters with him| however| because the Opposition Party (Lamestream Media) covers everything up - especially the corruption. The Obama/Biden Administration is the most corrupt in history. They even spied on my campaign!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 95,
                "tweet_content": "The Lamestream Media| including @FoxNews| which has really checked out| is refusing to show what is REALLY going on in Portland| Seattle| and other places. They want the American public to believe that these are just some wonderful protesters| not radical left ANARCHISTS!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 96,
                "tweet_content": "The Lamestream Media refuses to talk about our Country's record setting Jobs Numbers| which are indicating GREATNESS| and soon!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 97,
                "tweet_content": "Major China Virus flare ups in many of the countries that the Fake News was touting as doing so well. Some of these countries are now a disaster. Lamestream Media doesn't want to report this. Also| highly inaccurate numbers being reported by many other countries!!! @WSJ",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 98,
                "tweet_content": "We have more Cases because we do more Testing. It's Lamestream Media Gold!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 99,
                "tweet_content": "If a Republican LIED like Biden and Harris do| constantly| the Lamestream Media would be calling them out at a level never recorded before. For one year they called for No Fracking and big Tax Increases. Now they each say opposite. Fake News is working overtime!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 100,
                "tweet_content": "SO MUCH FAKE NEWS! The Lamestream Media has gone absolutely insane because they realize we are winning BIG in all of the polls that matter. They write or show one false story after another. They are truly sick people. VOTE!!!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 101,
                "tweet_content": "Remember| Stock Market at an All Time High| and going up. 401k's at RECORD. With Sleepy Joe comes Tax Increases| jobs losses and DEPRESSION like never seen before!!! VOTE!!!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 102,
                "tweet_content": "The Radical Left Dems and LameStream Media are just trying to make it hard for Republicans and me to win in 2020. The new Impeachment Hoax is already turning against them!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 103,
                "tweet_content": "....The LameStream Media| which is The Enemy of the People| is working overtime with made up stories in order to drive dissension and distrust!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 104,
                "tweet_content": "...Thank you @foxandfriends! Hopefully this is just the beginning of a massive story of injustice and treason. You will never learn this from the corrupt LameStream Media who get Pulitzer Prizes for reporting the story totally wrong. The ones who report it right get only RESPECT!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 105,
                "tweet_content": "So the so-called HHS Whistleblower was against HYDROXYCHLOROQUINE. Then why did he make| and sign| an emergency use authorization? @NorahODonnell said| \"He shared his concerns with a reporter.\" In other words| he LEAKED. A dumb @60Minutes hit job on a grandstanding Never Trumper!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 106,
                "tweet_content": "740 Park Avenue is being robbed all over the place--we come down hard on thieves at Trump buildings.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 107,
                "tweet_content": "Now the Chinese are hacking @nytimes &amp Twitter. When will we hold these thieves accountable?",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 108,
                "tweet_content": "DOJ just issued the McCabe report - which is a total disaster. He LIED! LIED! LIED! McCabe was totally controlled by Comey - McCabe is Comey!! No collusion| all made up by this den of thieves and lowlifes!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 109,
                "tweet_content": "We believe these people are thieves. The big city machines are corrupt. This was a stolen election. Best pollster in Britain wrote this morning that this clearly was a stolen election| that it's impossible to imagine that Biden outran Obama in some of these states.",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 110,
                "tweet_content": "Great. Most corrupt Election in history| by far. We won!!!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 111,
                "tweet_content": "These are the things and events that happen when a sacred landslide election victory is so unceremoniously &amp; viciously stripped away from great patriots who have been badly &amp; unfairly treated for so long. Go home with love &amp; in peace. Remember this day forever!",
                "tweet_year": 2021
            },
            {
                "tweet_type": "real",
                "id": 112,
                "tweet_content": "Despite the constant negative press covfefe",
                "tweet_year": 2017
            },
            {
                "tweet_type": "real",
                "id": 113,
                "tweet_content": "....to President of the United States (on my first try). I think that would qualify as not smart| but genius....and a very stable genius at that!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 114,
                "tweet_content": "Throughout my life| my two greatest assets have been mental stability and being| like| really smart. Crooked Hillary Clinton also played these cards very hard and| as everyone knows| went down in flames. I went from VERY successful businessman| to top T.V. Star.....",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 115,
                "tweet_content": "Very sad that the FBI missed all of the many signals sent out by the Florida school shooter. This is not acceptable. They are spending too much time trying to prove Russian collusion with the Trump campaign - there is no collusion. Get back to the basics and make us all proud!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 116,
                "tweet_content": "I hope Oprah gives Lance Armstrong 100 million dollars because that's what that ridiculous interview will cost him!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 117,
                "tweet_content": "Just watched a very insecure Oprah Winfrey| who at one point I knew very well| interview a panel of people on 60 Minutes. The questions were biased and slanted| the facts incorrect. Hope Oprah runs so she can be exposed and defeated just like all of the others!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 118,
                "tweet_content": "Lance Armstrong fought for 7 years &amp| then just ran out of energy. Very sad story--although they caught him red handed.He definitely cheated!",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 119,
                "tweet_content": "I guess they have Lance Armstrong cold. Brutal report. A waste of taxpayer money to take down an American hero.",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 120,
                "tweet_content": "I believe Lance Armstrong had death wish when he did interview w/Oprah—as I predicted| everybody is suing him| he'll have nothing left",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 121,
                "tweet_content": "Lance Armstrong just got sued by the Federal Government-they want their money back-I told you so! What was he thinking when he did that int?",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 122,
                "tweet_content": "Lance Armstrong is now being sued by Fed Govt-what was he thinkking?",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 123,
                "tweet_content": "@Oprah was great-- amazing that she got Lance Armstrong to totally destroy his life. Why did he ever do that interview?",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 124,
                "tweet_content": "Lance Armstrong did himself great harm last night. Lawsuits &amp| failure will follow him!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 125,
                "tweet_content": "@tman99us @_KatherineWebb  That's Shaquille O'Neal's shoe.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 126,
                "tweet_content": "The interview with Oprah will cause Lance Armstrong huge legal and financial problems- sometimes it is better to go into a corner and hide.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 127,
                "tweet_content": "I'll bet Lance Armstrong wishes he didn't do the interview with Oprah-- he's saying to himself \"what was I thinking?\"",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 128,
                "tweet_content": "@HBO should fire @BillMaher and bring back @DennisDMZ- someone that is actually funny.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 129,
                "tweet_content": "@BillMaher's show is great for helping me get to sleep--- better than Sominex.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 130,
                "tweet_content": ".@BillMaher needs to cut back on the pot| and maybe he will stop making offers he can't afford.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 131,
                "tweet_content": "Dumbass @BillMaher has still not given me the 5 million he committed to charity--- we just presented him with a demand notice.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 132,
                "tweet_content": "“Do your homework before you invest.  A dumb investor is a dead investor.”  - Think Like a Billionaire",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 133,
                "tweet_content": "Lance Armstrong is now going to admit guilt—can that be possible after many years of denying? Just go away Lance.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 134,
                "tweet_content": "THE VAST SILENT MAJORITY IS ALIVE AND WELL!!! We will win this Election big. Nobody wants a Low IQ person in charge of our Country| and Sleepy Joe is definitely a Low IQ person!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 135,
                "tweet_content": "North Korea fired off some small weapons| which disturbed some of my people| and others| but not me. I have confidence that Chairman Kim will keep his promise to me| &amp| also smiled when he called Swampman Joe Biden a low IQ individual| &amp| worse. Perhaps that's sending me a signal?",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 136,
                "tweet_content": "Robert De Niro| a very Low IQ individual| has received too many shots to the head by real boxers in movies. I watched him last night and truly believe he may be “punch-drunk.” I guess he doesn't...",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 137,
                "tweet_content": "@FoxNews and as far as that low-info voter base goes| I have an IQ of 132. So much for that theory. #MakeAmericaGreatAgain",
                "tweet_year": 2015
            },
            {
                "tweet_type": "real",
                "id": 138,
                "tweet_content": "Highly untalented Wash Post blogger| Jennifer Rubin| a real dummy| never writes fairly about me. Why does Wash Post have low IQ people?",
                "tweet_year": 2015
            },
            {
                "tweet_type": "real",
                "id": 139,
                "tweet_content": "@GovernorPerry failed on the border. He should be forced to take an IQ test before being allowed to enter the GOP debate.",
                "tweet_year": 2015
            },
            {
                "tweet_type": "real",
                "id": 140,
                "tweet_content": "\"@A11_Seeing_Eye: @realDonaldTrump Does an IQ of 60 count as being smart?\" No| sadly you are very dumb-but probably born that way.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 141,
                "tweet_content": "Scotland is having a virtual revolt over obsolete wind turbines which are driving up energy costs and killing the bird population (and more)",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 142,
                "tweet_content": "\"@Gurning_Chimp: @realDonaldTrump No| YOU hate wind power as YOU think it'll mess with YOUR golf course.\" That's true also!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 143,
                "tweet_content": "@hardball_chris must have the lowest IQ on television—now telling people that domestic terrorists are from the right.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 144,
                "tweet_content": "I must admit that Lyin' Brian Williams is| while dumber than hell| quite a bit smarter than Fake News @CNN “anchorman” Don Lemon| the “dumbest man on television”. Then you have Psycho Joe “What Ever Happened To Your Girlfriend?” Scarborough| another of the low I.Q. individuals!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 145,
                "tweet_content": "Lyin' Brian Williams of MSDNC| a Concast Scam Company| wouldn't know the truth if it was nailed to his wooden forehead. Remember when he lied about his bravery in a helicopter? Totally made up story. He's a true dummy who was thrown off Network News like a dog. Stay tuned!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 146,
                "tweet_content": "Just told that Fake News @CNN is falsely reporting that I was recently shouting at my campaign manager over made up nonsense. Actually| he is doing a great job| I never shouted at him (been with me for years| including the 2016 win)| &amp; have no intention to do so. Just FAKE NEWS!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 147,
                "tweet_content": "The Governor of the State did a good job. That may be true but she could not have done it without the tremendous economic success of our Country &amp| the turnaround that my Administration has caused. Really Nasty to me in his average I.Q. Columns| kissed my a.. on the call. Phony!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 148,
                "tweet_content": "Thomas “the Chin” Friedman| a weak and pathetic sort of guy| writes columns for The New York Times in between rounds of his favorite game| golf.",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 149,
                "tweet_content": "Joe Biden got tongue tied over the weekend when he was unable to properly deliver a very simple line about his decision to run for President. Get used to it| another low I.Q. individual!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 150,
                "tweet_content": "Elizabeth Warren is being hammered| even by the Left. Her false claim of Indian heritage is only selling to VERY LOW I.Q. individuals!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 151,
                "tweet_content": "Weak and totally conflicted people like @TheRickWilson shouldn't be allowed on television unless given an I.Q. test. Dumb as a rock! @CNN",
                "tweet_year": 2015
            },
            {
                "tweet_type": "real",
                "id": 152,
                "tweet_content": "Sorry losers and haters| but my I.Q. is one of the highest -and you all know it! Please don't feel so stupid or insecure| it's not your fault",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 153,
                "tweet_content": "\"@davidpylejr: @realDonaldTrump you're not really smart Mr. Trump| you're an F-ing genius!\" I.Q. tests confirm!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 154,
                "tweet_content": "Sexual pervert &amp| deviant Anthony Weiner is polling to see if he can run for NYC Mayor...Is Anthony Weiner also delusional? Add him to NY Sex Offender list instead!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 155,
                "tweet_content": "The Amazon Washington Post and three lowlife reporters| Matt Zapotosky| Josh Dawsey| and Carol Leonnig| wrote another Fake News story| without any sources (pure fiction)| about Bill Barr &amp| myself. We both deny this story| which they knew before they wrote it. A garbage newspaper!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 156,
                "tweet_content": "Adam Schiff should only be so lucky to have the brains| honor and strength of Secretary of State Mike Pompeo. For a lowlife like Schiff| who completely fabricated my words and read them to Congress as though they were said by me| to demean a First in Class at West Point| is SAD!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 157,
                "tweet_content": "When you give a crazed| crying lowlife a break| and give her a job at the White House| I guess it just didn't work out. Good work by General Kelly for quickly firing that dog!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 158,
                "tweet_content": "While I know it's “not presidential” to take on a lowlife like Omarosa| and while I would rather not be doing so| this is a modern day form of communication and I know the Fake News Media will be working overtime to make even Wacky Omarosa look legitimate as possible. Sorry!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 159,
                "tweet_content": "The big story that the Fake News Media refuses to report is lowlife Christopher Steele's many meetings with Deputy A.G. Bruce Ohr and his beautiful wife| Nelly. It was Fusion GPS that hired Steele to write the phony &amp| discredited Dossier| paid for by Crooked Hillary.",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 160,
                "tweet_content": "Thank you to Kanye West and the fact that he is willing to tell the TRUTH. One new and great FACT - African American unemployment is the lowest ever recorded in the history of our Country. So honored by this. Thank you Kanye for your support. It is making a big difference!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 161,
                "tweet_content": "Democrats| please do not distance yourselves from Nancy Pelosi. She is a wonderful person whose ideas &amp| policies may be bad| but who should definitely be given a 4th chance. She is trying very hard &amp| has every right to take down the Democrat Party if she has veered too far left!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 162,
                "tweet_content": "The NFL players are at it again - taking a knee when they should be standing proudly for the National Anthem. Numerous players| from different teams| wanted to show their “outrage” at something that most of them are unable to define.",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 163,
                "tweet_content": "DOJ just issued the McCabe report - which is a total disaster. He LIED! LIED! LIED! McCabe was totally controlled by Comey - McCabe is Comey!! No collusion| all made up by this den of thieves and lowlifes!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 164,
                "tweet_content": "@elizabethforma Goofy Elizabeth Warren| sometimes referred to as Pocahontas because she faked the fact she is native American| is a lowlife!",
                "tweet_year": 2016
            },
            {
                "tweet_type": "real",
                "id": 165,
                "tweet_content": "Amazingly| with all of the money I have raised for the vets| I have got nothing but bad publicity from the dishonest and disgusting media.",
                "tweet_year": 2016
            },
            {
                "tweet_type": "real",
                "id": 166,
                "tweet_content": "Lying #Ted Cruz just (on election day) came out with a sneak and sleazy Robocall. He holds up the Bible but in fact is a true lowlife pol!",
                "tweet_year": 2016
            },
            {
                "tweet_type": "real",
                "id": 167,
                "tweet_content": "\"@natestott: @realDonaldTrump how come you don't like Alan sugar Donald?\" Because he's a lowlife| but he makes me money!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 168,
                "tweet_content": "I have many great people but also an amazing number of haters and losers responding to my tweets-why do these lowlifes follow-nothing to do!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 169,
                "tweet_content": "Twitter will soon be irrelevant if lowlifes are so easily able to hack into accounts.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 170,
                "tweet_content": "When you're “hot” the lowlifes really shoot at you... and they try hitting from every angle! Never let the bastards get you down.",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 171,
                "tweet_content": "Everybody tells me not to hit back at the lowlifes that go after me for PR--sorry| but I must. It's my nature.",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 172,
                "tweet_content": "It is ashame that Congress doesn't do something about the lowlifes that burn the American Flag. It should be stopped| and now!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 173,
                "tweet_content": "Washed up Creepster John Bolton is a lowlife who should be in jail| money seized| for disseminating| for profit| highly Classified information. Remember what they did to the young submarine sailor| but did nothing to Crooked Hillary. I ended up pardoning him - It wasn't fair!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 174,
                "tweet_content": "Any protesters| anarchists| agitators| looters or lowlifes who are going to Oklahoma please understand| you will not be treated like you have been in New York| Seattle| or Minneapolis. It will be a much different scene!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 175,
                "tweet_content": "NYC| CALL UP THE NATIONAL GUARD. The lowlifes and losers are ripping you apart. Act fast! Don't make the same horrible and deadly mistake you made with the Nursing Homes!!!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 176,
                "tweet_content": "Goofy political pundit George Will spoke at Mar-a-Lago years ago. I didn't attend because he's boring &amp| often wrong—a total dope!",
                "tweet_year": 2015
            },
            {
                "tweet_type": "real",
                "id": 177,
                "tweet_content": "Crooked Hillary is wheeling out one of the least productive senators in the U.S. Senate| goofy Elizabeth Warren| who lied on heritage.",
                "tweet_year": 2016
            },
            {
                "tweet_type": "real",
                "id": 178,
                "tweet_content": "Goofy Elizabeth Warren| sometimes referred to as Pocahontas| pretended to be a Native American in order to advance her career. Very racist!",
                "tweet_year": 2016
            },
            {
                "tweet_type": "real",
                "id": 179,
                "tweet_content": "I find it offensive that Goofy Elizabeth Warren| sometimes referred to as Pocahontas| pretended to be Native American to get in Harvard.",
                "tweet_year": 2016
            },
            {
                "tweet_type": "real",
                "id": 180,
                "tweet_content": "I don't want to hit Crazy Bernie Sanders too hard yet because I love watching what he is doing to Crooked Hillary. His time will come!",
                "tweet_year": 2016
            },
            {
                "tweet_type": "real",
                "id": 181,
                "tweet_content": "Goofy Elizabeth Warren and her phony Native American heritage are on a Twitter rant. She is too easy! I'm driving her nuts.",
                "tweet_year": 2016
            },
            {
                "tweet_type": "real",
                "id": 182,
                "tweet_content": "When Mitt Romney asked me for my endorsement last time around| he was so awkward and goofy that we all should have known he could not win!",
                "tweet_year": 2016
            },
            {
                "tweet_type": "real",
                "id": 183,
                "tweet_content": "Mitt Romney| who totally blew an election that should have been won and whose tax returns made him look like a fool| is now playing tough guy",
                "tweet_year": 2016
            },
            {
                "tweet_type": "real",
                "id": 184,
                "tweet_content": "The polls show that I picked up many Jeb Bush supporters. That is how I got to 46%. When others drop out| I will pick up more. Sad but true",
                "tweet_year": 2016
            },
            {
                "tweet_type": "real",
                "id": 185,
                "tweet_content": "Thank you Kanye| very cool!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 186,
                "tweet_content": "Just spoke to @KanyeWest about his friend A$AP Rocky's incarceration. I will be calling the very talented Prime Minister of Sweden to see what we can do about helping A$AP Rocky. So many people would like to see this quickly resolved!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 187,
                "tweet_content": "@Beardymilne @wtfagain David--I hear you are jealous &amp| angry that Forbes got the award over you--two losers!",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 188,
                "tweet_content": "@pizzaman77 In retrospect I agree with you.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 189,
                "tweet_content": "Ellen was so awkward and insecure last night. The pizza skit was terrible. She should dump Andy Lassner| a guy with no absolutely no talent!",
                "tweet_year": 2014
            },
            {
                "tweet_type": "real",
                "id": 190,
                "tweet_content": "@PapaJohns CEO John Schnatte has told shareholders that ObamaCare will force him to raise pizza prices. REPEAL!",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 191,
                "tweet_content": "A$AP Rocky released from prison and on his way home to the United States from Sweden. It was a Rocky Week| get home ASAP A$AP!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 192,
                "tweet_content": "Give A$AP Rocky his FREEDOM. We do so much for Sweden but it doesn't seem to work the other way around. Sweden should focus on its real crime problem! #FreeRocky",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 193,
                "tweet_content": "Very disappointed in Prime Minister Stefan Löfven for being unable to act. Sweden has let our African American Community down in the United States. I watched the tapes of A$AP Rocky| and he was being followed and harassed by troublemakers. Treat Americans fairly! #FreeRocky",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 194,
                "tweet_content": "To the geniuses at 'Americans United for Change': the more you tax me the less people I employ. Get it?",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 195,
                "tweet_content": "\"@iSmoke_POTter: @realDonaldTrump is a genius when's it comes to business\" Thank you!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 196,
                "tweet_content": "Denver| Minnesota and others are bracing for some of the coldest weather on record. What are the global warming geniuses saying about this?",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 197,
                "tweet_content": "\"@Hunterboss1996: @realDonaldTrump Trump really knows what he's talking about. Everyone is just too jealous to listen. Lol.\" Thank you!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 198,
                "tweet_content": "Putin is having such a good time. Our President is making him look like the genius of all geniuses. Do not fear|we are a NATION OF POTENTIAL",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 199,
                "tweet_content": "\"@PhilipinDC: I've finally unfollowed @realDonaldTrump\"  I doubt it loser but| if so| you'll be back because you're lonely &amp| admire genius!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 200,
                "tweet_content": "\"@Bobbysoo80: @realDonaldTrump as if you would ever be president!! Some of your followers are pure RETARDS!\" Don't underestimate their genius",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 201,
                "tweet_content": "26|000 unreported sexual assults in the military-only 238 convictions. What did these geniuses expect when they put men &amp| women together?",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 202,
                "tweet_content": "\"@wesley_minga: @realDonaldTrump you're a genius!\" Thank you| you are so correct!",
                "tweet_year": 2014
            },
            {
                "tweet_type": "real",
                "id": 203,
                "tweet_content": "Funny how the failing @nytimes is pushing Dems narrative that Russia is working for me because Putin said \"Trump is a genius.\" America 1st!",
                "tweet_year": 2016
            },
            {
                "tweet_type": "real",
                "id": 204,
                "tweet_content": "I never said Russia did not meddle in the election| I said “it may be Russia| or China or another country or group| or it may be a 400 pound genius sitting in bed and playing with his computer. The Russian \"hoax\" was that the Trump campaign colluded with Russia - it never did!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 205,
                "tweet_content": "Finally| Liddle' Adam Schiff| the leakin' monster of no control| is now blaming the Obama Administration for Russian meddling in the 2016 Election. He is finally right about something. Obama was President| knew of the threat| and did nothing. Thank you Adam!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 206,
                "tweet_content": "Funny how the Fake News Media doesn't want to say that the Russian group was formed in 2014| long before my run for President. Maybe they knew I was going to run even though I didn't know!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 207,
                "tweet_content": "\"A Very Stable Genius!\" Thank you.",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 208,
                "tweet_content": "Pervert alert-serial sexter @RepWeiner is making another step towards a comeback. All girls under 18 should block him.",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 209,
                "tweet_content": "Sarah Jessica Parker voted \"unsexiest woman alive\" - I agree.",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 210,
                "tweet_content": "Danger-Weiner is a free man at 12:01AM. He will be back sexting with a vengeance. All women remain on alert.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 211,
                "tweet_content": "\"@ChrisCJackson: @realDonaldTrump I went to Trump Scotland last week and a gopher sexually assaulted my wife.\" After you| bet she loved it!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 212,
                "tweet_content": "\"@Nate_StuLLeR: @realDonaldTrump what was your bank account lookin like at age 24?\" Not too great!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 213,
                "tweet_content": "Pervert Alert! Serial sexter @anthonyweiner has promised to use twitter as a \"tool.\" Parents|make sure your children have him blocked.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 214,
                "tweet_content": "Weiner says many more pictures may be out there—this is just what NYC needs| a pervert Mayor.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 215,
                "tweet_content": "Wow| I'm at 2|200|000  followers but I'd love to get rid of the haters &amp| losers—they're such a waste of time!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 216,
                "tweet_content": "Sexting Pervert @anthonyweiner has returned to twitter. Parents of all underage girls should BLOCK him immediately!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 217,
                "tweet_content": "Hillary Clinton has announced that she is letting her husband out to campaign but HE'S DEMONSTRATED A PENCHANT FOR SEXISM| so inappropriate!",
                "tweet_year": 2015
            },
            {
                "tweet_type": "real",
                "id": 218,
                "tweet_content": "Hillary| when you complain about \"a penchant for sexism\"| who are you referring to. I have great respect for women. BE CAREFUL!",
                "tweet_year": 2015
            },
            {
                "tweet_type": "real",
                "id": 219,
                "tweet_content": "Do you ever notice that lightweight @megynkelly constantly goes after me but when I hit back it is totally  sexist. She is highly overrated!",
                "tweet_year": 2015
            },
            {
                "tweet_type": "real",
                "id": 220,
                "tweet_content": "\"@HoustonWelder: Donald Trump is one of the sexiest men on this planet. Every woman dreams of a good man who tells it like it is.\" So true!",
                "tweet_year": 2015
            },
            {
                "tweet_type": "real",
                "id": 221,
                "tweet_content": "\"@seenontv66: When will People magazine finally realize that THE sexiest man alive is Donald Trump! You are sexy and adorable! #gotmyvote\" Thanks!",
                "tweet_year": 2015
            },
            {
                "tweet_type": "real",
                "id": 222,
                "tweet_content": "Rep Tlaib wants to cut off aid to Israel. This is the new face the of Democrat Party? Read the AOC PLUS 3 statements on their hatred of Jews and Israel. Check out Rep. Omar (the great people of Minnesota won't stand for this).",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 223,
                "tweet_content": "Denmark is a very special country with incredible people| but based on Prime Minister Mette Frederiksen's comments| that she would have no interest in discussing the purchase of Greenland| I will be postponing our meeting scheduled in two weeks for another time....",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 224,
                "tweet_content": "Sorry| I don't buy Rep. Tlaib's tears. I have watched her violence| craziness and| most importantly| WORDS| for far too long. Now tears? She hates Israel and all Jewish people. She is an anti-Semite. She and her 3 friends are the new face of the Democrat Party. Live with it!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 225,
                "tweet_content": "In 2016| the ABC News/Washington Post Poll was such a complete disaster that these two Fake News Organizations changed the numbers prior to the Election. Now these haters are trying the same thing| though on a lesser scale| again. Will have a bigger win than even 2016!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 226,
                "tweet_content": "\"@twinmama85: I love the faux confidence gives people on Twitter...your haters would never say these things to your face.\" Not haters|losers!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 227,
                "tweet_content": "FRACK NOW &amp| FRACK FAST!!! American prosperity depends on it. Our economic renaissance is here.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 228,
                "tweet_content": "Happy Thanksgiving to all--even the haters and losers!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 229,
                "tweet_content": "Tell Iran to let our Christian Pastor go--and I mean right now. If they don't| there will be hell to pay.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 230,
                "tweet_content": "When I jokingly said \"bring back Steve Jobs to run Apple\" because Apple has not been doing well| the haters &amp| losers had a field day!  Sad.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 231,
                "tweet_content": "I've just started blocking out some of the repetitive and boring (&amp| dumb) haters and losers. They are a waste of time and energy!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 232,
                "tweet_content": "I can't believe Apple isn't moving faster to create a larger iPhone screen. Bring back Steve Jobs!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 233,
                "tweet_content": "\"@JamesMattiske: @realDonaldTrump the haters are just jelous because they are not successful like you\"  The haters are losers with no guts!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 234,
                "tweet_content": "\"@m_i_c_h_a_e_l_m: @realDonaldTrump you are a very handsome man\" I know| but the haters and losers never give me credit for that! Too bad.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 235,
                "tweet_content": "Sharks are last on my list - other than perhaps the losers and haters of the World!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 236,
                "tweet_content": "What my father really gave me is a good (great) brain| motivation and the benefit of his experience-unlike the haters and losers (lazy!).",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 237,
                "tweet_content": "I own @DannyZuker| but he has his friends &amp| haters &amp| losers tweeting that he beat me. He can't beat me at anything!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 238,
                "tweet_content": "\"@JIMHENDLEY81: @realDonaldTrump Hey Donald I always find it funny that so many of your haters follow you on twitter.\" Can't live without me!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 239,
                "tweet_content": "\"@PattyOchs: @realDonaldTrump why people who are your haters feel they have to talk to you or follow you?\" They are sick and mentally weak!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 240,
                "tweet_content": "\"@asaadhutchinson: @realDonaldTrump how do you deal with haters?\" Laugh at them!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 241,
                "tweet_content": "\"@gshuba: @realDonaldTrump Windmills not worth the trouble. Worse for the planet than oil| carbon| et al.\" True!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 242,
                "tweet_content": "Amazing how the haters &amp| losers keep tweeting the name \"F**kface Von Clownstick\" like they are so original &amp| like no one else is doing it...",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 243,
                "tweet_content": "Wow| I'm at 2|200|000  followers but I'd love to get rid of the haters &amp| losers—they're such a waste of time!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 244,
                "tweet_content": "As everybody knows| but the haters &amp| losers refuse to acknowledge| I do not wear a \"wig.\" My hair may not be perfect but it's mine.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 245,
                "tweet_content": "I have many great people but also an amazing number of haters and losers responding to my tweets-why do these lowlifes follow-nothing to do!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 246,
                "tweet_content": "\"@Jarius_Rembert: @realDonaldTrump has a lot of haters.\" Not haters| losers!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 247,
                "tweet_content": "I hope everyone (especially the haters and losers) goes to Macy's today and buys some DJT ties| shirts and suits-and SUCCESS Fragrance-love!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 248,
                "tweet_content": "Sorry losers and haters| but I LOVED the great energy in Madison Square Garden during my speech. The WWE thought it was incredible - it was!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 249,
                "tweet_content": "Haters| stop saying I went bankrupt| it is not so. I never went bankrupt...",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 250,
                "tweet_content": "Losers and haters|even you| as low and dumb as you are| can learn from watching Apprentice and checking out my tweets-you can still succeed!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 251,
                "tweet_content": "Losers and haters are invited to watch Celebrity Apprentice along with the many great and productive people in the hope that you will learn.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 252,
                "tweet_content": "The many losers and haters never have the brains or stamina to become truly successful!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 253,
                "tweet_content": "If I'm the third most envied man in America| the small group of haters and losers must be nauseas.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 254,
                "tweet_content": "It's okay but why do the haters (&amp| losers) want to follow me on twitter?? Get a life!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 255,
                "tweet_content": "To EVERYONE| including all haters and losers| HAPPY NEW YEAR. Work hard| be smart and always remember| WINNING TAKES CARE OF EVERYTHING!",
                "tweet_year": 2014
            },
            {
                "tweet_type": "real",
                "id": 256,
                "tweet_content": "Happy 4th of July to everyone| including the haters and losers!",
                "tweet_year": 2014
            },
            {
                "tweet_type": "real",
                "id": 257,
                "tweet_content": "I would like to wish all fathers| even the haters and losers| a very happy Fathers Day.",
                "tweet_year": 2014
            },
            {
                "tweet_type": "real",
                "id": 258,
                "tweet_content": "Haters and losers say I wear a wig (I don't)| say I went bankrupt (I didn't)| say I'm worth $3.9 billion (much more). They know the truth!",
                "tweet_year": 2014
            },
            {
                "tweet_type": "real",
                "id": 259,
                "tweet_content": "To all haters and losers: I am NOT anti-vaccine| but I am against shooting massive doses into tiny children. Spread shots out over time.",
                "tweet_year": 2014
            },
            {
                "tweet_type": "real",
                "id": 260,
                "tweet_content": "\"@christuhglo: @realDonaldTrump I love how you handle the haters\" You mean the haters and losers!",
                "tweet_year": 2014
            },
            {
                "tweet_type": "real",
                "id": 261,
                "tweet_content": "For all of the haters and losers out there sorry| I never went Bankrupt -- but I did build a world class company and employ many people!",
                "tweet_year": 2015
            },
            {
                "tweet_type": "real",
                "id": 262,
                "tweet_content": "I wish everyone| including the haters and losers| a very happy Easter!",
                "tweet_year": 2015
            },
            {
                "tweet_type": "real",
                "id": 263,
                "tweet_content": "When will all the haters and fools out there realize that having a good relationship with Russia is a good thing| not a bad thing. There always playing politics - bad for our country. I want to solve North Korea| Syria| Ukraine| terrorism| and Russia can greatly help!",
                "tweet_year": 2017
            },
            {
                "tweet_type": "real",
                "id": 264,
                "tweet_content": "The fact that I am having a meeting is a major loss for the U.S.| say the haters &amp| losers. We have our hostages| testing| research and all missle launches have stoped| and these pundits| who have called me wrong from the beginning| have nothing else they can say! We will be fine!",
                "tweet_year": 2018
            },
            {
                "tweet_type": "real",
                "id": 265,
                "tweet_content": "HAPPY NEW YEAR TO EVERYONE| INCLUDING THE HATERS AND THE FAKE NEWS MEDIA! 2019 WILL BE A FANTASTIC YEAR FOR THOSE NOT SUFFERING FROM TRUMP DERANGEMENT SYNDROME. JUST CALM DOWN AND ENJOY THE RIDE| GREAT THINGS ARE HAPPENING FOR OUR COUNTRY!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 266,
                "tweet_content": "I would like to extend my best wishes to all| even the haters and losers| on this special date| September 11th.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 267,
                "tweet_content": "Lyin' Ted Cruz even voted against Superstorm Sandy aid and September 11th help. So many New Yorkers devastated. Cruz hates New York!",
                "tweet_year": 2016
            },
            {
                "tweet_type": "real",
                "id": 268,
                "tweet_content": "A study says @Autism is out of control--a 78% increase in 10 years. Stop giving monstrous combined vaccinations.",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 269,
                "tweet_content": "Ugly industrial wind turbines are ruining the beauty of parts of the country--and have inefficient unreliable energy to boot.",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 270,
                "tweet_content": "Wind Farms are not only disgusting to look at but also cause tremendous damage to their local ecosystems",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 271,
                "tweet_content": "Why would Ohio listen to Bruce Springsteen reading his lines? Be careful or I will go to Ohio and @MittRomney will win it!",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 272,
                "tweet_content": "Watching Pyongyang terrorize Asia today is just amazing!",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 273,
                "tweet_content": "With the record high February gas prices hurting the economy| even more reason to start fracking.  Will create jobs &amp| lower prices.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 274,
                "tweet_content": "Healthy young child goes to doctor| gets pumped with massive shot of many vaccines| doesn't feel good and changes - AUTISM. Many such cases!",
                "tweet_year": 2014
            },
            {
                "tweet_type": "real",
                "id": 275,
                "tweet_content": "The deep state| or whoever| over at the FDA is making it very difficult for drug companies to get people in order to test the vaccines and therapeutics. Obviously| they are hoping to delay the answer until after November 3rd. Must focus on speed| and saving lives!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 276,
                "tweet_content": "Joe Biden is promising to delay the vaccine and turn America into a prison state—locking you in your home while letting far-left rioters roam free. The Biden Lockdown will mean no school| no graduations| no weddings| no Thanksgiving| no Christmas| no Fourth of July.",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 277,
                "tweet_content": "STOCK MARKET UP BIG| VACCINE COMING SOON. REPORT 90% EFFECTIVE. SUCH GREAT NEWS!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 278,
                "tweet_content": "STOCK MARKET GETTING VERY CLOSE TO 30|000 ON NEW VACCINE NEWS. 95% EFFECTIVE!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 279,
                "tweet_content": "European Countries are sadly getting clobbered by the China Virus. The Fake News does not like reporting this!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 280,
                "tweet_content": "Lots of autism and vaccine response. Stop these massive doses immediately. Go back to single| spread out shots! What do we have to lose.",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 281,
                "tweet_content": "Autism rates through the roof--why doesn't the Obama administration do something about doctor-inflicted autism. We lose nothing to try.",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 282,
                "tweet_content": "I am fighting the Fake (Corrupt) News| the Deep State| the Democrats| and the few remaining Republicans In Name Only (RINOS| who are on mouth to mouth resuscitation)| with the help of some truly great Republicans| and others. We are Winning big (150th Federal Judge this week)!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 283,
                "tweet_content": "Some of the best Economic Numbers our Country has ever experienced are happening right now. This is despite a Crooked and Demented Deep State| and a probably illegal Democrat/Fake News Media Partnership the likes of which the world has never seen. MAKE AMERICA GREAT AGAIN!",
                "tweet_year": 2019
            },
            {
                "tweet_type": "real",
                "id": 284,
                "tweet_content": "Let's take a closer look at that birth certificate. @BarackObama was described in 2003 as being \"born in Kenya\".",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 285,
                "tweet_content": "Will Smith did a great job by smacking the guy \"reporter\" who kissed him on the lips at a red carpet event.",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 286,
                "tweet_content": "Why are people upset w/ me over Pres Obama's birth certificate?I got him to release it| or whatever it was| when nobody else could!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 287,
                "tweet_content": "Sleepy Joe Biden's 1994 Crime Bill was a total disaster. It was mass incarceration for Black people| many of them innocent. I did Criminal Justice Reform| something Obama &amp; Biden didn't even try to do - &amp; couldn't do even if they did try. Biden can never escape his Crime Bill!",
                "tweet_year": 2020
            },
            {
                "tweet_type": "real",
                "id": 288,
                "tweet_content": "Big cancer risk from new \"environmental light bulbs\"--a big price to pay!",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 289,
                "tweet_content": "It makes me feel so good to hit \"sleazebags\" back -- much better than seeing a psychiatrist (which I never have!)",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 290,
                "tweet_content": "The media is so after me on women  Wow| this is a tough business. Nobody has more respect for women than Donald Trump!",
                "tweet_year": 2016
            },
            {
                "tweet_type": "real",
                "id": 291,
                "tweet_content": "Lyin' Ted Cruz just used a picture of Melania from a G.Q. shoot in his ad. Be careful| Lyin' Ted| or I will spill the beans on your wife!",
                "tweet_year": 2016
            },
            {
                "tweet_type": "real",
                "id": 292,
                "tweet_content": "I will be doing the A.L.S. Ice Bucket Challenge this morning on twitter. It is not something I look forward to doing but is for a good cause",
                "tweet_year": 2014
            },
            {
                "tweet_type": "real",
                "id": 293,
                "tweet_content": "If Christian Bale turned down $50M to return as Batman he should have his head examined. What was he thinking?!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 294,
                "tweet_content": "So| lets get this right. Steve Jobs dies and leaves his wife everything-billions of dollars. Now his wife has a boyfriend (lover). Oh Steve!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 295,
                "tweet_content": "Steve Jobs is spinning in his grave - Apple has lost both vision and momentum - must move fast to get magic back!",
                "tweet_year": 2014
            },
            {
                "tweet_type": "real",
                "id": 296,
                "tweet_content": "I've always been a fan of Steve Jobs| especially after watching Apple stock collapse w/out him - but the yacht he built is truly ugly.",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 297,
                "tweet_content": "I have a lot of @Apple stock--- and I miss Steve Jobs. Tim Cook must immediately increase the size of the screen...",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 298,
                "tweet_content": "I have never seen a thin person drinking  Diet Coke.",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 299,
                "tweet_content": "I don't care what people say| I like Tom Cruise. He works his ass off and never ever quits. He's one of the few true movie stars.",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 300,
                "tweet_content": "Windmills are a bigger safety hazard than either coal or oil. A 34% higher mortality rate than coal alone. Outrageous!",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 301,
                "tweet_content": "Windmills are the greatest threat in the US to both bald and golden eagles. Media claims fictional 'global warming' is worse.",
                "tweet_year": 2014
            },
            {
                "tweet_type": "real",
                "id": 302,
                "tweet_content": "Barney Frank looked disgusting--nipples protruding--in his blue shirt before Congress. Very very disrespectful.",
                "tweet_year": 2011
            },
            {
                "tweet_type": "real",
                "id": 303,
                "tweet_content": "I went to Wharton| made over $8 billion| employ thousands of people &amp| get insulted by morons who can't get enough of me on twitter...!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 304,
                "tweet_content": "Isn't it crazy| I'm worth billions of dollars| employ thousands of people| and get libeled by  moron bloggers who can't afford a suit! WILD.",
                "tweet_year": 2014
            },
            {
                "tweet_type": "real",
                "id": 305,
                "tweet_content": "@BetteMidler talks about my hair but I'm not allowed to talk about her ugly face or body --- so I won't. Is this a double standard?",
                "tweet_year": 2012
            },
            {
                "tweet_type": "real",
                "id": 306,
                "tweet_content": "If you are lucky enough to catch a knockout assaulter before getting slugged| and you carry a gun| shoot the bastard (teach them a lesson)!",
                "tweet_year": 2013
            },
            {
                "tweet_type": "real",
                "id": 307,
                "tweet_content": "It was 3 on 1| but they were mentally challenged people| against one person of extroadinary genius. It wasn't even close.",
                "tweet_year": 2024
            },
            {
                "tweet_type": "real",
                "id": 308,
                "tweet_content": "I HATE TAYLOR SWIFT!",
                "tweet_year": 2024
            },
            {
                "tweet_type": "real",
                "id": 309,
                "tweet_content": "I watched a portion of low rated (very) Morning Psycho (Joe) this Morning in order to see what Nancy Pelosi had to say| &amp; what moves she was planning to further hurt our Country. Actually| other than her usual complaining that I'm a terrible person| she wasn't bad. Still praying!",
                "tweet_year": 2020
            },
        ]

    };

    // Select one random tweet from each type
    const fakeTweet = getRandomElement(tweets.fake);
    const realTweet = getRandomElement(tweets.real);

    return {
        "tweets": [fakeTweet, realTweet]
    };
}